import js.errors.JsError
import kotlinext.js.js
import kotlinx.browser.window
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import mui.icons.material.AttachMoney
import mui.icons.material.Done
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.*
import tech.gensert.portal.common.model.CheckoutSession
import tech.gensert.portal.common.model.PlanService
import tech.gensert.portal.common.model.SessionService
import tech.gensert.portal.common.model.User
import tech.gensert.portal.common.model.config.CookieNames
import tech.gensert.portal.common.model.config.getCookie
import tech.gensert.portal.common.model.config.getStoredTheme
import tech.gensert.portal.web.showcase.Reusables.WebReusables.MODE
import tech.gensert.portal.web.showcase.Reusables.WebReusables.SUCCESS_URL
import tech.gensert.portal.web.theme.Colors.BACKGROUND_DEFAULT_LIGHT
import tech.gensert.portal.web.theme.Colors.BACKGROUND_PAPER_LIGHT
import tech.gensert.portal.web.theme.Colors.BLACK
import tech.gensert.portal.web.theme.Colors.DARK_CHARCOAL
import tech.gensert.portal.web.theme.Colors.PRIMARY_DARK
import tech.gensert.portal.web.theme.Colors.PRIMARY_MAIN
import web.cssom.*


@Serializable
data class CardData(
    val title: String?,
    val description: String?,
    val price: String?,
    val productId: String,
)


@OptIn(DelicateCoroutinesApi::class)
val ServicesCard = FC<Props> {

    val theme = useTheme()
    var (dataLoaded, setDataLoaded) = useState(false)
    var (cardDataList, setCardDataList) = useState(emptyList<CardData>())
    var (selectedPlan, setSelectedPlan) = useState(null)
    var (selectedInterval, setSelectedInterval) = useState("year")
    var (loading, setLoading) = useState(true)
    val userId = getCookie<String>(CookieNames.UserAccountId)?.replace("\"", "")
    val userName = getCookie<String>(CookieNames.UserName)?.replace("\"", "")
    val userEmail = getCookie<String>(CookieNames.UserEmail)?.replace("\"", "")
    val setTheme = useSetTheme()
    val theme1 = getStoredTheme()
    val storedTheme = if (theme1 == "dark") Themes.Dark else Themes.Light
    setTheme(storedTheme)
    val cardStyle: dynamic = js {
        width = "80%"
        height = "450px"
        margin = "8px"
        padding = "5px"
        backgroundColor =
            if ((theme == Themes.Dark)) Color(DARK_CHARCOAL) else Color(BACKGROUND_PAPER_LIGHT)
        color = if ((theme == Themes.Dark)) BACKGROUND_PAPER_LIGHT else PRIMARY_DARK
        borderRadius = "15px"
        display = "flex"
        flexDirection = "column"
        transition = "transform 0.3s"
        border = "solid 2.5px "
        borderColor = theme.palette.secondary.main
        marginTop = "45px"
        overflow = "hidden"
        fontSize = "20.px"

        sx = js {
            hover = js {
                transform = "scale(1.1)"
            }
        }
    }


    suspend fun handleGetPlans(planName: String?, interval: String) {
        setLoading(true)
        try {
            val planService = PlanService().getPlans(null, planName, interval)
            val combinedDataList = mutableListOf<CardData>()
            if (planService.isEmpty()) {
                throw JsError("Plans not found")
            } else {
                planService.forEach { plan ->
                    try {
                        val product = plan.products?.firstOrNull()
                        val productId = product?.productId ?: return@forEach

                        val cardData = CardData(
                            title = plan.name,
                            description = plan.description,
                            price = plan.price ?: "0",
                            productId = productId
                        )
                        combinedDataList.add(cardData)
                    } catch (error: Throwable) {
                        console.error("Error getting products for plan ${plan.name}", error)
                    }
                }
            }
            setCardDataList(combinedDataList)
        } catch (error: Throwable) {
            console.error("Error getting plans", error)
        } finally {
            setLoading(false)
        }
    }

    suspend fun handleSession(cardData: CardData) {
        setLoading(true)
        try {
            val checkoutSession = CheckoutSession(
                successUrl = SUCCESS_URL,
                mode = MODE,
                interval = selectedInterval,
                user = User(userId!!, userName!!, userEmail!!),
                stripeCustomerId = null
            )
            val response = SessionService().createSession(null, checkoutSession, cardData.productId)
            window.location.href = response.url
            setLoading(false)
        } catch (error: Throwable) {
            console.error("Error getting checkout url", error)
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    useEffect(selectedInterval) {
        console.log("success url", SUCCESS_URL)
        if (!dataLoaded) {
            GlobalScope.launch {
                try {
                    handleGetPlans(selectedPlan, selectedInterval)
                    setDataLoaded(true)
                } catch (e: Throwable) {
                    console.error("Error in handleGetPlans: ${e.message}")
                }
            }
        }
    }

    if (loading) {
        return@FC Grid {
            sx {
                justifyContent = JustifyContent.center
                marginTop = 320.px
                marginLeft = 650.px
            }
            CircularProgress {
                sx = js {
                    color = CircularProgressColor.secondary
                }
                size = 80
                thickness = 4
            }
        }
    }
    val handleSwitchChange: (String) -> Unit = { interval ->
        setSelectedInterval(interval)
        GlobalScope.launch {
            handleGetPlans(selectedPlan, interval)
        }
    }
    Grid {
        container = true
        spacing = responsive(2)
        sx {
            justifyContent = JustifyContent.center
            marginTop = 20.px
        }
        Grid {
            item = true
            sx {
                display = Display.flex
                alignItems = AlignItems.center
                flexDirection = FlexDirection.column
            }
            Typography {
                variant = TypographyVariant.h3
                gutterBottom = true
                +("SubscriptionType Plans")
            }
        }
        Grid {
            container = true
            spacing = responsive(2)
            sx {
                justifyContent = JustifyContent.end
                marginTop = 20.px
            }
            FormControlLabel {
                control = buildElement {
                    ToggleButtonGroup {
                        attrs.exclusive = true
                        attrs.value = selectedInterval
                        attrs.onChange = { _, value -> handleSwitchChange(value as String) }
                        ToggleButton {
                            attrs.value = "year"
                            attrs.style = js {
                                backgroundColor =
                                    if (selectedInterval == "year") PRIMARY_MAIN else BACKGROUND_PAPER_LIGHT
                                color = if (selectedInterval == "year") BACKGROUND_DEFAULT_LIGHT else BLACK
                            }
                            +"Year"
                        }
                        ToggleButton {
                            attrs.value = "month"
                            attrs.style = js {
                                backgroundColor =
                                    if (selectedInterval == "month") PRIMARY_MAIN else BACKGROUND_PAPER_LIGHT
                                color = if (selectedInterval == "month") BACKGROUND_DEFAULT_LIGHT else BLACK
                            }
                            +"Month"
                        }
                    }
                }
            }
        }
        Grid {
            container = true
            spacing = responsive(2)
            cardDataList.forEachIndexed { index, cardData ->
                Grid {
                    item = true
                    key = index.toString()
                    sx {
                        width = 450.px
                        marginBottom = 20.px
                        marginLeft=40.px
                    }
                    Card {
                        sx = cardStyle
                        CardContent {
                            Typography {
                                gutterBottom = true
                                variant = TypographyVariant.h5
                                sx {
                                    fontSize = FontSize.xLarge
                                    marginTop = 5.pct
                                    textTransform = TextTransform.uppercase
                                    fontWeight = FontWeight.normal
                                }
                                +(cardData.title?.uppercase() ?: "")
                            }
                            Box {
                                Typography {
                                    sx {
                                        marginTop = 20.px
                                    }
                                    AttachMoney {}
                                    variant = TypographyVariant.h2
                                    sx {
                                        display = Display.flex
                                        alignItems = AlignItems.end
                                        flexDirection = FlexDirection.row
                                        justifyContent = JustifyContent.end
                                        marginLeft = 5.px
                                        color =
                                            if (theme.palette.mode == PaletteMode.dark) Color(BACKGROUND_PAPER_LIGHT) else Color(
                                                PRIMARY_DARK
                                            )
                                        fontSize = 16.px
                                        marginBottom = 200.px
                                    }
                                    +(cardData.price.toString())
                                }
                            }
                            Box {
                                Typography {
                                    sx {
                                        marginTop = 20.px
                                        color =
                                            if (theme.palette.mode == PaletteMode.dark) Color(BACKGROUND_PAPER_LIGHT) else Color(
                                                PRIMARY_DARK
                                            )
                                    }
                                    variant = TypographyVariant.body2
                                    Done {
                                        sx {
                                            color = Color(PRIMARY_MAIN)
                                        }
                                    }
                                    sx {
                                        display = Display.flex
                                        alignItems = AlignItems.start
                                        flexDirection = FlexDirection.row
                                        justifyContent = JustifyContent.start
                                        marginLeft = 5.px
                                        marginTop = -180.px
                                        color =
                                            if (theme.palette.mode == PaletteMode.dark) Color(BACKGROUND_PAPER_LIGHT) else Color(
                                                PRIMARY_DARK
                                            )
                                        fontSize = 16.px
                                    }
                                    +(cardData.description)
                                }
                            }
                        }
                        Box {
                            sx {
                                display = Display.flex
                                justifyContent = JustifyContent.center
                                paddingTop = 220.px
                            }
                            Button {
                                variant = ButtonVariant.contained
                                color = ButtonColor.primary
                                sx {
                                    width = 200.px
                                    backgroundColor = Color(PRIMARY_MAIN)
                                    color = Color("#d9d9d9")
                                    borderRadius = 9.px
                                }
                                onClick = {
                                    GlobalScope.launch {
                                        handleSession(cardData)
                                    }
                                }
                                +"Get Started"
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RBuilder.cardGrid() = child(ServicesCard) {}




