package tech.gensert.portal.common.config
object Version {
    const val V1 = "v1"
}

object Port {
    const val PORT = "8080"
}

object Endpoint {
    const val BASE_URL = "/api/${Version.V1}"
    const val PRODUCTS_URL = "$BASE_URL/products"
    const val CATEGORY_URL = "$BASE_URL/category"
    const val PLAN_URL = "$BASE_URL/plan"
    const val INVOICE_URL = "$BASE_URL/invoice"
    const val SUBSCRIPTION_URL = "$BASE_URL/subscription"
    const val SESSION_URL = "$BASE_URL/session"
    const val BACKUP_URL = "$BASE_URL/backup"

}