package tech.gensert.portal.web.config.msal

import kotlin.js.json

private const val clientId = "c20b4014-0439-47f3-9b8d-e43ba1f0db17"
private const val policyId = "B2C_1_susi"
private const val tenantName = "GensertTechnologiesB2Cdev"
private const val domain = "GensertTechnologiesB2Cdev.onmicrosoft.com"

/*
Make sure to update the redirectUri when working locally
 */
val msalConfig = json(
    "auth" to json(
        "clientId" to clientId,
        "authority" to "https://$tenantName.b2clogin.com/$domain/$policyId",
        "redirectUri" to "/",
        "postLogoutRedirectUri" to "/",
        "knownAuthorities" to arrayOf("$tenantName.b2clogin.com"),
        "navigateToLoginRequestUrl" to false
    ),
    "cache" to json(
        "cacheLocation" to "sessionStorage",
        "storeAuthStateInCookie" to false
    )
)