package tech.gensert.portal.web.pages

import Header
import Sidebar
import mui.system.Box
import mui.system.sx
import react.FC
import useIsMobile
import web.cssom.*

val Page = FC {
    val isMobile = useIsMobile()
    Box {
        sx {
            display = Display.grid
            height = 100.pct
            overflow = Overflow.visible
            gridTemplateRows = array(
                Sizes.Header.Height,
                Auto.auto,
                Length.maxContent,
            )
            gridTemplateColumns = if (isMobile) {
                array(Auto.auto)
            } else {
                array(Sizes.Sidebar.Width, Auto.auto)
            }
            gridTemplateAreas = if (isMobile) {
                GridTemplateAreas(
                    arrayOf(Area.Header),
                    arrayOf(Area.Content),
                    arrayOf(Area.Footer),
                )
            } else {
                GridTemplateAreas(
                    arrayOf(Area.Header, Area.Header),
                    arrayOf(Area.Sidebar, Area.Content),
                    arrayOf(Area.Footer, Area.Footer),
                )
            }
        }
        Header()
        if (!isMobile) Sidebar()
        Content()
    }
}
