package tech.gensert.portal.web.routes

import ErrorPage
import ServicesCard
import js.core.jso
import react.router.dom.createHashRouter
import remix.run.router.LoaderFunction
import tech.gensert.portal.web.pages.LandingPageShowcase
import tech.gensert.portal.web.pages.Page
import tech.gensert.portal.web.showcase.PaymentAndSubscriptionShowcase
import tech.gensert.portal.web.showcase.SettingsShowcase
import kotlin.js.Promise

val MATERIAL_SHOWCASES: Array<out Showcase> = arrayOf(
    Showcase("/services", "Services", ServicesCard),
    Showcase("subscriptions", "Subscriptions", PaymentAndSubscriptionShowcase),
    Showcase("settings", "Settings", SettingsShowcase),
)

val ShowcaseMaterialLoader: LoaderFunction = { args ->
    val showcaseId = args.params["showcaseId"]
    val showcase = MATERIAL_SHOWCASES.find { it.key == showcaseId }
    Promise.resolve(showcase ?: throw IllegalArgumentException("Showcase not found for ID: $showcaseId"))
}



val PageLoader: LoaderFunction = { _ ->
    Promise.resolve(MATERIAL_SHOWCASES)
}

/*
    We can certainly do better here by taking advantage of AuthenticatedTemplate and UnAuthenticatedTemplate
    provided by @azure/msal-react
    to conditionally render pages based on the authentication status. Perhaps from the router directly.
*/
val appRouter = createHashRouter(
    arrayOf(
        jso {
            path = "/"
            loader = PageLoader
            Component = LandingPageShowcase
            ErrorBoundary = ErrorPage
        },
        jso {
            path = "/"
            loader = PageLoader
            Component = Page
            ErrorBoundary = ErrorPage
            children = arrayOf(
                jso {
                    path = "services"
                    loader = PageLoader
                    Component = ServicesCard
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = ":showcaseId"
                    loader = ShowcaseMaterialLoader
                    Component = ShowcaseMaterial
                    ErrorBoundary = ErrorPage
                },
                jso {
                    path = "*"
                    Component = ErrorPage
                }

            )
        }
    )
)
