package tech.gensert.portal.common.model

import kotlinx.serialization.Serializable

@Serializable
actual data class Invoice (
    actual var id: String,
    actual var accountName: String,
    actual var amountDue: Long,
    actual var amountPaid: Long,
    actual var amountRemaining: Long,
    actual var created: String,
    actual var currency: String,
    actual var customer: String,
    actual var customerEmail: String,
    actual var invoicePdfUrl: String,
)
