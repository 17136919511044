
import mui.icons.material.Notifications
import mui.material.Badge
import mui.material.BadgeColor
import mui.system.Box
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML
import react.useState
import tech.gensert.portal.web.pages.Area
import tech.gensert.portal.web.showcase.HamburgerMenuShowcase
import tech.gensert.portal.web.showcase.Searchbar
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.px

val Header = FC {
    val (isSearchVisible, setSearchVisible) = useState(false)
    val (isAvatarMenuVisible, setAvatarMenuVisible) = useState(false)
    Box {
        sx {
            gridArea = Area.Content
            marginTop= (-60).px
        }
        component = ReactHTML.main
        Box {
            sx {
                display = Display.flex
                justifyContent = JustifyContent.end
                alignItems = AlignItems.center
            }
            Box {
                sx {
                    marginTop=6.px
                }
                Searchbar {}
            }
            Badge {
                color = BadgeColor.primary
                //badgeContent = ReactNode("")
                onClick = { setAvatarMenuVisible(!isAvatarMenuVisible) }
                Notifications()
            }
            HamburgerMenuShowcase {}
        }
    }
}