package tech.gensert.portal.web.routes

import react.FC
import react.Props

data class Showcase(
    val key: String,
    val name: String,
    val component: FC<Props>,
)
