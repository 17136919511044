package tech.gensert.portal.web.showcase

import Themes
import kotlinx.browser.document
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.span
import tech.gensert.portal.common.model.InvoiceService
import tech.gensert.portal.common.model.SubscriptionService
import tech.gensert.portal.common.model.config.CookieNames
import tech.gensert.portal.common.model.config.getCookie
import tech.gensert.portal.common.model.enums.Interval
import tech.gensert.portal.web.theme.Colors.BACKGROUND_DEFAULT_LIGHT
import tech.gensert.portal.web.theme.Colors.BACKGROUND_PAPER_LIGHT
import tech.gensert.portal.web.theme.Colors.BLACK
import tech.gensert.portal.web.theme.Colors.GREY
import tech.gensert.portal.web.theme.Colors.PRIMARY_DARK
import tech.gensert.portal.web.theme.Colors.PRIMARY_MAIN
import tech.gensert.portal.web.theme.Colors.RED
import useTheme
import web.cssom.*
import web.html.HTMLAnchorElement

@OptIn(DelicateCoroutinesApi::class)
val PaymentAndSubscriptionShowcase = FC<Props> {
    var selectedTab by useState(0)
    var theme = useTheme()
    val userEmail = getCookie<String>(CookieNames.UserEmail)?.replace("\"", "")
    val b2cId = getCookie<String>(CookieNames.UserAccountId)?.replace("\"", "")
    var invoiceList by useState<List<InvoiceDetail>>(emptyList())
    var subscriptionList by useState<List<SubscriptionDetail>>(emptyList())
    val statusList = listOf(
        StatusDetail("#1042", "20/06/2024", "In Progress"),
        StatusDetail("#1030", "20/05/2024", "Done"),
        StatusDetail("#1020", "20/04/2024", "Done"),
        StatusDetail("#1010", "20/03/2024", "Done")
    )

    suspend fun getInvoices(email: String) {
        try {
            val invoiceService = InvoiceService().getUserInvoices(email)
            val invoices = if (invoiceService.isEmpty()) {
                emptyList()
            } else {
                invoiceService.map { invoice ->
                    InvoiceDetail(
                        id = invoice.id,
                        date = invoice.created,
                        pdfUrl = invoice.invoicePdfUrl,
                        amountDue = invoice.amountDue
                    )
                }
            }
            invoiceList = invoices
        } catch (error: Throwable) {
            console.error("Error getting invoices", error)
        }
    }

    suspend fun getSubscriptions(b2cId: String) {
        try {
            val subscriptionService = SubscriptionService().getClientSubscriptions(b2cId)
            val subscriptions = if (subscriptionService.isEmpty()) {
                emptyList()
            } else {
                subscriptionService.map { sub ->
                    SubscriptionDetail(
                        id = sub.id,
                        expiryDate = sub.expiryDate,
                        productName = sub.productName,
                        price = sub.price,
                        interval = sub.interval
                    )
                }
            }
            subscriptionList = subscriptions
        } catch (error: Throwable) {
            console.error("Error getting invoices", error)
        }
    }

    useEffect(userEmail) {
        GlobalScope.launch {
            if (userEmail != null) {
                getInvoices(userEmail)
                getSubscriptions(b2cId!!)
            }
        }
    }

    Box {
        sx {
            padding = 16.px
            margin = Auto.auto
            color = Color(PRIMARY_DARK)
        }
        Tabs {
            value = selectedTab
            onChange = { _, newValue -> selectedTab = newValue }
            sx {
                "& .MuiTab-root" {
                    color = if (theme == Themes.Dark) Color("$GREY !important") else Color("$BLACK !important")
                }
                "& .Mui-selected" {
                    color =
                        if (theme == Themes.Dark) Color("$BACKGROUND_DEFAULT_LIGHT !important") else Color("$BLACK !important")
                    fontWeight = integer(600)
                }
            }
            Tab {
                label = ReactNode("Overview")
            }
            Tab {
                label = ReactNode("Subscriptions")
            }
//            Tab {
//                label = ReactNode("Status")
//            }
            Tab {
                label = ReactNode("Invoices")
            }
        }
        SeparatorLine()
        when (selectedTab) {
            0 -> OverviewTab {
                invoices = invoiceList
                subscriptions = subscriptionList
            }

            1 -> SubscriptionTab {
                subscriptions = subscriptionList
            }
//            2 -> StatusTab {
//                statuses = statusList
//            }
            2 -> InvoiceTab {
                invoices = invoiceList
            }
        }
    }
}


external interface TabProps : Props {
    var statuses: List<StatusDetail>
    var invoices: List<InvoiceDetail>
    var subscriptions: List<SubscriptionDetail>
}

val OverviewTab = FC<TabProps> { props ->
    val theme = useTheme()
    Box {
        sx {
            padding = 16.px
        }
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
                gap = 16.px
            }
            SubscriptionTab {
                subscriptions = props.subscriptions
            }
//            Box {
//                sx {
//                    padding = 16.px
//                    backgroundColor = Color(BACKGROUND_DEFAULT_LIGHT)
//                    borderRadius = 8.px
//                    display = Display.flex
//                    flexDirection = FlexDirection.column
//                    width = 500.px
//                    height = 200.px
//                    border = Border(1.px, LineStyle.solid, Color(BLACK))
//                    marginTop = 48.px
//                }
//                Typography {
//                    variant = TypographyVariant.h6
//                    +"Status"
//                }
//                Typography {
//                    variant = TypographyVariant.subtitle1
//                    +"In progress"
//                }
//                LinearProgress {
//                    variant = LinearProgressVariant.determinate
//                    value = 50
//                    sx {
//                        marginTop = 8.px
//                        marginBottom = 8.px
//                        height = 15.px
//                        borderRadius = 8.px
//                        border = Border(1.px, LineStyle.solid, Color(BLACK))
//                        backgroundColor = Color(BACKGROUND_DEFAULT_LIGHT)
//                        "& .MuiLinearProgress-bar" {
//                            backgroundColor = Color(PRIMARY_MAIN)
//                            hover {
//                                backgroundColor = Color(RED)
//                            }
//                        }
//                    }
//                }
//            }
        }
        Typography {
            variant = TypographyVariant.subtitle1
            sx {
                marginTop = 16.px
                marginBottom = 30.px
                color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(BLACK)
                height = 1.px
                fontWeight = FontWeight.bold
            }
            +"Invoices"
        }
        SeparatorLine()
        InvoiceTab {
            invoices = props.invoices
        }
    }
}
val SubscriptionTab = FC<TabProps> { props ->
    val theme = useTheme()
    Box {
        sx {
            padding = 16.px
        }
//        Box {
//            sx {
//                display = Display.flex
//                flexDirection = FlexDirection.row
//                gap = 16.px
//            }
//        }
        Typography {
            variant = TypographyVariant.h6
            +"Customer Portal"
            sx {
                marginTop = 20.px
                marginBottom = 30.px
                color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(BLACK)
                height = 1.px
                fontWeight = FontWeight.bold
            }
        }
        props.subscriptions.forEach { subscription ->
            Box {
                key = subscription.id
                sx {
                    padding = 16.px
                    backgroundColor = if (theme == Themes.Dark) Color(PRIMARY_DARK) else Color(BACKGROUND_DEFAULT_LIGHT)
                    borderRadius = 8.px
                    display = Display.flex
                    justifyContent = JustifyContent.spaceBetween
                    alignItems = AlignItems.center
                    marginTop = 16.px
                    height = 200.px
                    width = 500.px
                    border = Border(1.px, LineStyle.solid, if (theme == Themes.Dark) Color(BACKGROUND_PAPER_LIGHT) else Color(PRIMARY_DARK))
                }
                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        gap = 8.px
                    }
                    Typography {
                        variant = TypographyVariant.h6
                        sx {
                            color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(PRIMARY_DARK)
                        }
                        +subscription.productName
                    }
                    Typography {
                        variant = TypographyVariant.subtitle2
                        sx {
                            color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(PRIMARY_DARK)
                            marginTop = 58.px
                        }
                        +"Expiry Date: ${subscription.expiryDate}"
                    }
                }
                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        gap = 8.px
                        alignItems = AlignItems.flexEnd
                    }
                    Typography {
                        variant = TypographyVariant.subtitle1
                        sx {
                            color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(PRIMARY_DARK)
                        }
                        +"${subscription.price}/${subscription.interval}"
                    }
                    Button {
                        variant = ButtonVariant.contained
                        sx {
                            marginTop = 8.px
                            backgroundColor = Color(PRIMARY_MAIN)
                            color = Color(BACKGROUND_DEFAULT_LIGHT)
                            hover {
                                backgroundColor = Color(RED)
                            }
                        }
                        +"Upgrade Plan"
                    }
                }
            }
        }
    }
}

//val StatusTab = FC<TabProps> { props ->
//    val theme = useTheme()
//    Box {
//        sx {
//            padding = 16.px
//        }
//        Box {
//            sx {
//                padding = 16.px
//                backgroundColor = Color(BACKGROUND_DEFAULT_LIGHT)
//                borderRadius = 8.px
//                display = Display.flex
//                flexDirection = FlexDirection.column
//                border = Border(1.px, LineStyle.solid, Color(BLACK))
//            }
//            Typography {
//                variant = TypographyVariant.h6
//
//                +"Status"
//            }
//            Typography {
//                variant = TypographyVariant.subtitle1
//                +"In progress"
//            }
//            LinearProgress {
//                variant = LinearProgressVariant.determinate
//                value = 50
//                sx {
//                    marginTop = 8.px
//                    marginBottom = 8.px
//                    height = 20.px
//                    borderRadius = 8.px
//                    border = Border(1.px, LineStyle.solid, Color(BLACK))
//                    backgroundColor = Color(BACKGROUND_DEFAULT_LIGHT)
//                    "& .MuiLinearProgress-bar" {
//                        backgroundColor = Color("#AE0E30")
//                        hover {
//                            backgroundColor = Color("#8E0B25")
//                        }
//                    }
//                }
//            }
//        }
//        Typography {
//            variant = TypographyVariant.h6
//            sx {
//                marginTop = 0.px
//                marginBottom = 30.px
//                color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(BLACK)
//                height = 1.px
//            }
//            +"Status Details"
//        }
//        SeparatorLine()
//        props.statuses.forEach { status ->
//            Box {
//                key = status.id
//                sx {
//                    display = Display.flex
//                    justifyContent = JustifyContent.spaceBetween
//                    alignItems = AlignItems.center
//                    padding = 8.px
//                    backgroundColor = Color("#fff")
//                    border = Border(1.px, LineStyle.solid, Color("#e0e0e0"))
//                    borderRadius = 8.px
//                    marginTop = 8.px
//                }
//                span {
//                    +status.id
//                }
//                span {
//                    +status.date
//                }
//                Button {
//                    variant = ButtonVariant.contained
//                    sx {
//                        marginTop = 8.px
//                        backgroundColor = Color("#AE0E30")
//                        color = Color(BACKGROUND_DEFAULT_LIGHT)
//                        hover {
//                            backgroundColor = Color("#8E0B25")
//                        }
//                    }
//                    +status.status
//                }
//            }
//        }
//    }
//}
val InvoiceTab = FC<TabProps> { props ->
    val theme = useTheme()
    Box {
        sx {
            padding = 16.px
        }
        if (props.invoices.isEmpty()) {
            Typography {
                variant = TypographyVariant.subtitle1
                sx {
                    color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(PRIMARY_DARK)
                }
                +"No invoices available."
            }
        } else {
            props.invoices.forEach { invoice ->
                Box {
                    key = invoice.id
                    sx {
                        display = Display.flex
                        justifyContent = JustifyContent.spaceBetween
                        alignItems = AlignItems.center
                        padding = 8.px
                        backgroundColor = if (theme == Themes.Dark) Color(PRIMARY_DARK) else Color(BACKGROUND_DEFAULT_LIGHT)
                        border = Border(1.px, LineStyle.solid, if (theme == Themes.Dark) Color(BACKGROUND_PAPER_LIGHT) else Color(PRIMARY_DARK))
                        borderRadius = 8.px
                        marginTop = 8.px
                    }
                    span {
                        Typography {
                            sx {
                                color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(PRIMARY_DARK)
                            }
                            +invoice.id
                        }
                    }
                    span {
                        Typography {
                            sx {
                                color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(PRIMARY_DARK)
                            }
                            +"Date of Invoice:${invoice.date}"
                        }
                    }
                    span {
                        Typography {
                            sx {
                                color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(PRIMARY_DARK)
                            }
                            +"Status: ${if (invoice.amountDue == 0L) "Paid" else "Unpaid"}"
                        }
                    }
                    Button {
                        variant = ButtonVariant.text
                        sx {
                            color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(RED)
                            hover {
                                backgroundColor = Color(RED)
                                color = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(BACKGROUND_DEFAULT_LIGHT)
                            }
                            border = Border(1.px, LineStyle.solid, if (theme == Themes.Dark) Color(BACKGROUND_PAPER_LIGHT) else Color(PRIMARY_DARK))
                        }
                        +"Download"
                        onClick = {
                            val link = document.createElement("a") as HTMLAnchorElement
                            link.href = invoice.pdfUrl
                            link.download = invoice.id + ".pdf"
                            link.click()
                        }
                    }
                }
            }
        }
    }
}

data class StatusDetail(val id: String, val date: String, val status: String)
data class InvoiceDetail(val id: String, val date: String, val pdfUrl: String, val amountDue: Long)
data class SubscriptionDetail(
    val id: String,
    val expiryDate: String,
    val productName: String,
    val price: String,
    val interval: Interval
)

private val SeparatorLine = FC {
    val theme = useTheme()
    Divider {
        light = true
        sx {
            marginTop = 0.px
            marginBottom = 30.px
            backgroundColor = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(BLACK)
            height = 1.px
        }
    }
}

