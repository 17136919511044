
import emotion.react.css
import emotion.styled.styled
import js.uri.decodeURIComponent
import js.uri.encodeURIComponent
import mui.icons.material.*
import mui.material.*
import mui.material.List
import mui.material.Mui.Companion.selected
import mui.material.Size
import mui.material.styles.TypographyVariant
import mui.system.Box
import mui.system.sx
import react.*
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.img
import react.router.dom.NavLink
import react.router.useLoaderData
import tech.gensert.portal.common.model.config.getStoredTheme
import tech.gensert.portal.web.config.cookies.storeTheme
import tech.gensert.portal.web.pages.Area
import tech.gensert.portal.web.pages.Sizes
import tech.gensert.portal.web.routes.Showcase
import tech.gensert.portal.web.routes.useShowcaseIdParam
import tech.gensert.portal.web.theme.Colors.BACKGROUND_DEFAULT_LIGHT
import tech.gensert.portal.web.theme.Colors.BACKGROUND_PAPER_LIGHT
import tech.gensert.portal.web.theme.Colors.BLACK
import tech.gensert.portal.web.theme.Colors.GREY
import tech.gensert.portal.web.theme.Colors.PRIMARY_DARK
import tech.gensert.portal.web.theme.Colors.PRIMARY_MAIN
import tech.gensert.portal.web.theme.Colors.VAMPIRE_BLACK
import web.cssom.*

val Sidebar = FC<Props> {
    val isMobile = useIsMobile()
    var isOpen by useState(false)
    val setTheme = useSetTheme()
    val theme1 = getStoredTheme()
    val storedTheme = if (theme1 == "dark") Themes.Dark else Themes.Light
    setTheme(storedTheme)
    Box {

        if (!isMobile) {
            sx {
                gridArea = Area.Sidebar
                width = Sizes.Sidebar.Width
            }
        }

        component = ReactHTML.nav

        if (isMobile) {
            SwipeableDrawer {
                anchor = DrawerAnchor.left
                open = isOpen
                onOpen = { isOpen = true }
                onClose = { isOpen = false }

                ShowcaseList()
            }

            SpeedDial {
                sx {
                    position = Position.absolute
                    bottom = 80.px
                    left = 16.px
                }

                ariaLabel = "Menu"

                icon = Slideshow.create()
                onClick = { isOpen = !isOpen }
            }
        } else {
            Drawer {
                variant = DrawerVariant.permanent
                anchor = DrawerAnchor.left

                ShowcaseList()
            }
        }
    }
}

private val ShowcaseList = FC {
    val showcases = useLoaderData().unsafeCast<Array<out Showcase>>()
    val theme = useTheme()
    val setTheme = useSetTheme()
    val theme1 = getStoredTheme()
    val storedTheme = if (theme1 == "dark") Themes.Dark else Themes.Light
    setTheme(storedTheme)

    val (_, setSelectedLink) = useState(encodeURIComponent("Services"))

    Box {

        sx {

            backgroundColor = Color(VAMPIRE_BLACK)
        }
        Toolbar {
            sx {
                display = Display.flex
                alignItems = AlignItems.center
            }

            img {
                alt = "Gensert Tech Logo"
                src = "icon-log.png"
                css {
                    height = 45.px
                    width = 45.px
                    marginRight = 10.px
                }
            }
            Typography {
                component = ReactHTML.div
                variant = TypographyVariant.subtitle1
                noWrap = true
                +"Gensert Tech"
            }
        }
        sx {
            gridArea = Area.Sidebar
        }
        component = ReactHTML.nav
        Toolbar()

        List {
            sx {
                width = Sizes.Sidebar.Width
                marginTop = (-50).px
            }
        }
        showcases.forEachIndexed { _, showcase ->
            val link = encodeURIComponent(showcase.key)
            val selectedLink = decodeURIComponent(useShowcaseIdParam())
            LinkButton {
                to = link
                ListItemButton {
                    sx {
                        textDecoration = None.none
                        backgroundColor =
                            if (selectedLink == showcase.key) Color(PRIMARY_MAIN) else if (theme == Themes.Dark) Color(PRIMARY_DARK) else Color(BACKGROUND_PAPER_LIGHT)
                        border = if (selectedLink == showcase.key) Border(
                            2.px, web.cssom.LineStyle.solid,
                            Color(PRIMARY_MAIN)
                        ) else None.none
                        borderRadius = 13.px
                        marginLeft = 10.px
                        marginRight = 10.px
                        marginTop = 10.px
                        hover {
                            backgroundColor = Color(PRIMARY_MAIN)
                            color = rgb(255, 255, 255)
                        }
                        selected {
                            backgroundColor = Color(PRIMARY_MAIN)
                            color = Color(PRIMARY_MAIN)
                            hover {
                                backgroundColor = Color(PRIMARY_MAIN)
                                color = rgb(255, 255, 255)
                                borderRadius = 20.px
                            }
                        }
                    }
                    onClick = { _ ->
                        setSelectedLink(link)
                    }
                    val isSelected = selectedLink == showcase.key
                    ListItemIcon {
                        when (showcase.name) {
                            "Services" -> Dvr()
                            "Subscriptions" -> CreditCard()
                            "Settings" -> Tune()
                        }

                        sx {
                            color = if (isSelected) Color(BACKGROUND_PAPER_LIGHT) else if (theme == Themes.Dark) rgb(
                                255,
                                255,
                                255
                            ) else Color.currentcolor
                            width = 24.px
                            height = 24.px
                            marginRight = 8.px
                        }
                    }

                    ListItemText {
                        primary = ReactNode(showcase.name)
                        sx {
                            color = if (isSelected) Color(BACKGROUND_PAPER_LIGHT) else Color.currentcolor
                            borderRadius = 130.px
                        }
                    }
                }
            }
            if (showcase.name == "Subscriptions") {
                SeparatorLine()
            }
        }
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
                alignItems = AlignItems.center
                gap = 8.px
                marginTop = 400.px
                backgroundColor = if (theme == Themes.Dark) Color(BLACK) else Color(GREY)
                borderRadius = 14.px
                padding=10.px
                marginLeft=80.px
                marginRight=100.px
                border = Border(
                    1.px,
                    web.cssom.LineStyle.solid,
                    if (theme == Themes.Dark) Color(BLACK) else Color(GREY)
                )
            }
            Button {
                onClick = {
                    setTheme(Themes.Dark)
                    storeTheme("dark")
                }
                startIcon = Bedtime.create {
                    sx {
                        color = if (theme == Themes.Dark) Color(PRIMARY_MAIN) else Color(BLACK)
                    }
                }
                disabled = theme == Themes.Dark
                size = Size.small
                sx {
                    backgroundColor = if (theme == Themes.Dark) Color(BLACK) else Color(GREY)
                    color = Color(BACKGROUND_DEFAULT_LIGHT)
                    marginLeft = 10.px
                    cursor = Cursor.pointer
                    "&:disabled" {
                        cursor = Cursor.notAllowed
                        opacity = 0.6.asDynamic().unsafeCast<Opacity>()
                    }
                }
            }
            Button {
                onClick = {
                    setTheme(Themes.Light)
                    storeTheme("light")
                }
                disabled = theme == Themes.Light
                startIcon = WbSunny.create {
                    sx {
                        color = if (theme == Themes.Light) Color(PRIMARY_MAIN) else Color(GREY)
                    }
                }

                size = Size.small
                sx {
                    backgroundColor =
                        if (theme == Themes.Light) Color(GREY) else Color(BLACK)
                    color = Color(BLACK)
                    cursor = Cursor.pointer
                    "&:disabled" {
                        cursor = Cursor.notAllowed
                        opacity = 0.6.asDynamic().unsafeCast<Opacity>()
                    }
                }
            }
        }
    }
}

private val LinkButton = NavLink.styled {
    textDecoration = None.none
    color = Color.currentcolor
}
private val SeparatorLine = FC {
    val theme = useTheme()
    Divider {
        light = true
        sx {
            marginTop = 30.px
            marginBottom = 30.px
            backgroundColor = if (theme == Themes.Dark) Color(BACKGROUND_DEFAULT_LIGHT) else Color(BLACK)
            height = 1.px
        }
    }
}