package tech.gensert.portal.web.theme
object Colors {
    // Primary Palette
    const val PRIMARY_MAIN = "#AE0E30"
    const val PRIMARY_DARK = "#303030"

    // Background Colors
    const val BACKGROUND_DEFAULT_LIGHT = "#FFFFFF"
    const val BACKGROUND_PAPER_LIGHT = "#D9D9D9"

    // Neutral Colors
    const val BLACK = "#000000"
    const val VAMPIRE_BLACK = "#060606"
    const val GREY = "#AAAAAA"
    const val LIGHT_GREY = "#E0E0E0"
    const val GHOST_WHITE = "#f9f9f9"
    const val DARK_CHARCOAL = "#333333"

    // Accent Colors
    const val RED = "#8E0B25"
}

