package tech.gensert.portal.web.config.cookies

import kotlinx.browser.document
import tech.gensert.portal.common.model.config.CookieNames
import tech.gensert.portal.web.wrappers.AccountInfo

fun storeUserAccount(account: AccountInfo) {
    val idCookie = "${CookieNames.UserAccountId}=${account.idTokenClaims.oid!!}"
    val emailCookie = "${CookieNames.UserEmail}=${account.idTokenClaims.emails!![0]}"
    val nameCookie = "${CookieNames.UserName}=${account.name}"
    document.cookie = idCookie
    document.cookie = emailCookie
    document.cookie = nameCookie
    val tokenCookie = "${CookieNames.Tokens}=${account.idToken}"
    document.cookie = tokenCookie
}
fun storeTheme(theme: String) {
    document.cookie = "theme=$theme; path=/; max-age=31536000"
}

