import js.core.jso
import mui.material.PaletteMode
import mui.material.styles.TypographyOptions
import mui.material.styles.TypographyVariant
import mui.material.styles.createTheme
import tech.gensert.portal.web.theme.Colors.BACKGROUND_DEFAULT_LIGHT
import tech.gensert.portal.web.theme.Colors.BACKGROUND_PAPER_LIGHT
import tech.gensert.portal.web.theme.Colors.PRIMARY_DARK
import tech.gensert.portal.web.theme.Colors.PRIMARY_MAIN
import web.cssom.atrule.maxWidth
import web.cssom.integer
import web.cssom.px
import web.cssom.rem

private val TYPOGRAPHY_OPTIONS = TypographyOptions {
    fontWeight = integer(400)
    TypographyVariant.h6 {
        fontSize = 1.5.rem
        media(maxWidth(599.px)) {
            fontSize = 1.25.rem
        }
    }
}

object Themes {
    val Light = createTheme(
        jso {
            palette = jso {
                mode = PaletteMode.light
                primary = jso {
                    main = PRIMARY_MAIN
                }
                secondary = jso {
                    main = PRIMARY_MAIN
                }
                background = jso {
                    default = BACKGROUND_DEFAULT_LIGHT
                    paper = BACKGROUND_PAPER_LIGHT
                }
            }
            typography = TYPOGRAPHY_OPTIONS
        }
    )

    val Dark = createTheme(
        jso {
            palette = jso {
                mode = PaletteMode.dark
                primary = jso {
                    main = PRIMARY_DARK
                }
                secondary = jso {
                    main = PRIMARY_MAIN
                }
                background = jso {
                    default = PRIMARY_DARK
                    paper = PRIMARY_DARK
                }
            }
            typography = TYPOGRAPHY_OPTIONS
        }
    )
}
