package tech.gensert.portal.common.model

import io.ktor.http.*
import tech.gensert.portal.common.config.Endpoint
import tech.gensert.portal.common.model.config.BuildEnv
import tech.gensert.portal.common.model.config.makeRequest

actual class InvoiceService {
    actual suspend fun getUserInvoices(email: String): List<Invoice> {
        val url = buildString {
            append("${BuildEnv.backendUrl}${Endpoint.INVOICE_URL}/getUserInvoices?")
            append("email=$email&")
        }
        return try {
            makeRequest<List<Invoice>>(url, HttpMethod.Get).also {
            }
        } catch (e: Throwable) {
            emptyList()
        }
    }
}