package tech.gensert.portal.web.showcase

import mui.icons.material.Search
import mui.material.Input
import mui.system.Box
import mui.system.sx
import org.w3c.dom.HTMLInputElement
import react.FC
import react.dom.html.ReactHTML.br
import react.useState
import web.cssom.*
import web.cssom.atrule.maxWidth


val Searchbar = FC {

    val (isSearchVisible, setSearchVisible) = useState(false)
    val (searchText, setSearchText) = useState("")
    val (searchResults, setSearchResults) = useState<List<String>>(emptyList())

    fun performSearch(query: String) {
    }

    Box {
        sx {
            display = Display.flex
            justifyContent = JustifyContent.end
            alignItems = AlignItems.center
            marginTop = -5.px
            marginRight = 3.px

        }

        Search {
            onClick = { setSearchVisible(!isSearchVisible) }
        }

        if (isSearchVisible) {
            Input {
                value = searchText
                onChange = {
                    val target = it.target as HTMLInputElement
                    val newSearchText = target.value
                    setSearchText(newSearchText)

                    performSearch(newSearchText)
                }
                sx {
                    background = rgb(217, 217, 217, 0.40)
                    color = Color("#303030")
                    borderRadius = 25.px
                    height = 35.px
                    padding = 5.px
                    width = 350.px
                    media(maxWidth(600.px)) {
                        width = 200.px
                    }
                }
            }


            if (searchResults.isNotEmpty()) {
                Box {
                    sx {
                        listStyleType = None.none
                        paddingInlineStart = 0.px
                    }

                    searchResults.forEach { result ->
                        Box {
                            +result
                            br {}
                        }
                    }
                }
            }
        }
    }
}