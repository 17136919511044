package tech.gensert.portal.common.model

import io.ktor.http.*
import tech.gensert.portal.common.config.Endpoint
import tech.gensert.portal.common.model.config.BuildEnv
import tech.gensert.portal.common.model.config.makeRequest

actual class SubscriptionService {
    actual suspend fun getSubscriptions(authToken: String?,type: String?): List<SubscriptionType> {
        TODO("Not yet implemented")
    }

    actual suspend fun getClientSubscriptions(b2cId: String): List<Subscription> {
        val url = buildString {
            append("${BuildEnv.backendUrl}${Endpoint.SUBSCRIPTION_URL}/client?")
            append("b2cId=$b2cId&")
        }
        return try {
            makeRequest<List<Subscription>>(url, HttpMethod.Get).also {
            }
        } catch (e: Throwable) {
            emptyList()
        }    }

}