package tech.gensert.portal.web.showcase.Reusables

import kotlinx.browser.window

object WebReusables {
    var location = window.location.origin

    var SUCCESS_URL = "$location/#/subscriptions"
    const val MODE = "subscription"
}
