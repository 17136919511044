package tech.gensert.portal.common.model

import kotlinx.serialization.Serializable
import tech.gensert.portal.common.model.enums.Interval

@Serializable
actual data class Subscription (
    actual val id: String,
    actual val startDate: String,
    actual val expiryDate: String,
    actual val productId: String,
    actual val productName: String,
    actual val price: String,
    actual val interval: Interval,
)
